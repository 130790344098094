<template>
  <HeroBg
    heroTitle="Build High performing remote teams with <br class='hidden xl:inline-block' /> handpicked, world-class talent"
    heroDesc="Our Talentpool makes hiring great talent fast and easy. Our unified platform provides you with complete access to talent profiles. Create your account, post a job (or several), and start browsing qualified candidates instantly."
    heroBtnText="Start Hiring"
    :heroBtnLink="$employerUrl + '/register'"
    heroImg="hero9.png"
  />

  <Customers />

  <div class="flex justify-center bg-brand-black">
    <div class="w-full lg:w-[65vw] px-[24px] md:px-0">
      <div class="py-[5rem]">
        <div class="px-[24px] md:px-0">
          <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-white leading-[110%] font-semibold">How it works</h2>
        </div>

        <div class="md:flex flex-wrap justify-center gap-8 pb-5">
          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8 flex justify-center items-center">
            <div class="text-center py-8">
              <img src="@/assets/icons/users.svg" class="mx-auto mb-4" alt="" />
              <h1 class="font-bold text-[18px]">Create unlimited jobs</h1>
            </div>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8 flex justify-center items-center">
            <div class="text-center py-8">
              <img src="@/assets/icons/users.svg" class="mx-auto mb-4" alt="" />
              <h1 class="font-bold text-[18px]">Browse qualified candidates</h1>
            </div>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8 flex justify-center items-center">
            <div class="text-center py-8">
              <img src="@/assets/icons/users.svg" class="mx-auto mb-4" alt="" />
              <h1 class="font-bold text-[18px]">Assess skills and experience</h1>
            </div>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8 flex justify-center items-center">
            <div class="text-center py-8">
              <img src="@/assets/icons/users.svg" class="mx-auto mb-4" alt="" />
              <h1 class="font-bold text-[18px]">Schedule Interviews</h1>
            </div>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8 flex justify-center items-center">
            <div class="text-center py-8">
              <img src="@/assets/icons/users.svg" class="mx-auto mb-4" alt="" />
              <h1 class="font-bold text-[18px]">Hire the right talent instantly</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[72vw] px-[24px] md:px-0">
      <div class="py-[5rem]">
        <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Benefits</h2>

        <div>
          <div
            class="block md:flex items-center bg-no-repeat bg-contain bg-right-top p-8 rounded-2xl bg-white mb-6"
            :style="`background-image: url(${require('@/assets/images/card-bg1.png')})`"
          >
            <img src="@/assets/images/icons/price2.png" class="mr-6 w-[50px] md:w-[90px] h-[50px] md:h-[90px] mb-2 md:mb-0" alt="" />
            <div>
              <p class="text-[18px] md:text-[30px] uppercase leading-[110%] mb-2">Find the right talent with ease</p>
              <p>
                CareerBuddy Talent platform provides complete transparency before, during and after the hiring process. Leave the ground work to us.
              </p>
            </div>
          </div>

          <div
            class="block md:flex items-center bg-no-repeat bg-contain bg-right-top p-8 rounded-2xl bg-white mb-6"
            :style="`background-image: url(${require('@/assets/images/card-bg1.png')})`"
          >
            <img src="@/assets/images/icons/therapy.png" class="mr-6 w-[50px] md:w-[90px] h-[50px] md:h-[90px] mb-2 md:mb-0" alt="" />
            <div>
              <p class="text-[18px] md:text-[30px] uppercase leading-[110%] mb-2">Manage Manage your hiring process hiring process</p>
              <p>ind, communicate with the right candidates all in one location. Add your team</p>
            </div>
          </div>

          <div
            class="block md:flex items-center bg-no-repeat bg-contain bg-right-top p-8 rounded-2xl bg-white mb-6"
            :style="`background-image: url(${require('@/assets/images/card-bg1.png')})`"
          >
            <img src="@/assets/images/icons/therapy.png" class="mr-6 w-[50px] md:w-[90px] h-[50px] md:h-[90px] mb-2 md:mb-0" alt="" />
            <div>
              <p class="text-[18px] md:text-[30px] uppercase leading-[110%] mb-2">Performance Assessment</p>
              <p>Provide feedback on talent to drive continuous improvement.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import Customers from '@/components/partials/Customers.vue';
import HeroBg from '@/components/partials/HeroBg.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import { useMeta } from '@/utils/meta';
useMeta();
</script>
